/* General Modal Styles */
.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
}

.modal.open {
    display: flex;
}

.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    max-height: 80%;
    /* Limit modal height */
    overflow-y: auto;
    /* Enable vertical scrolling when content overflows */
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: left;
}

/* Ensure scrolling only happens for the modal content */
.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.modal-content::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Header and input fields styles */
h2 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 15px;
}

p {
    font-size: 16px;
    color: #6b7280;
}

input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

input[type="color"] {
    width: 100%;
    height: 45px;
    border: none;
    padding: 0;
    border-radius: 6px;
}

/* Add link button */
.add-link-btn {
    color: #007bff;
    background-color: white;
    border: 1px solid #007bff;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease-in;
}

.add-link-btn:hover {
    background-color: aliceblue;
}

/* Error input styles */
input.error {
    border-color: red;
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: -8px;
    margin-bottom: 10px;
}

/* Color Picker and Link Fields */
.color-picker {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.color-picker input {
    padding: 0;
    border: none;
}

/* Modal actions */
.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cancel-btn,
.submit-btn {
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
}

.cancel-btn {
    background-color: #e5e7eb;
    color: #111827;
}

.cancel-btn:hover {
    background-color: #d1d5db;
}

.submit-btn {
    color: #007bff;
    background-color: white;
    border: 1px solid #007bff;
}

.submit-btn:hover {
    background-color: aliceblue;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .modal-content {
        width: 70%;
        padding: 20px;
    }

    h2 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }

    input {
        font-size: 14px;
    }

    .add-link-btn,
    .cancel-btn,
    .submit-btn {
        font-size: 14px;
        padding: 10px;
    }

    .modal-actions {
        flex-direction: column;
        gap: 10px;
    }
}

/* Desktop Styles */
@media (min-width: 769px) {
    .modal-content {
        padding: 40px;
        width: 70%;
    }

    h2 {
        font-size: 26px;
    }

    p {
        font-size: 18px;
    }

    input {
        font-size: 16px;
    }

    .add-link-btn,
    .cancel-btn,
    .submit-btn {
        font-size: 16px;
        padding: 12px 20px;
    }

    .modal-actions {
        flex-direction: row;
    }
}

.head {
    text-align: start;
    font-size: 1.2rem;
    font-weight: 500;


}



.add-image-btn {
    display: block;
    text-align: center;
    color: #007bff;
    background-color: white;
    border: 1px solid #007bff;
    width: 30%;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 2%;
}

.add-image-btn:hover {
    background-color: aliceblue;
}

.add-icon-btn {
    display: block;
    text-align: center;
    color: #007bff;
    background-color: white;
    border: 1px solid #007bff;
    width: 30%;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 2%;
}

.add-icon-btn:hover {
    background-color: aliceblue;
}

.add-header {
    text-align: center;
}

@media (max-width: 769px) {
    .add-icon-btn {
        display: block;
        text-align: center;
        width: 40%;
        font-size: 0.9rem;
    }

    .add-image-btn {
        display: block;
        text-align: center;
        width: 40%;
        font-size: 0.9rem;
    }
}

@media (max-width: 391px) {
    .add-icon-btn {
        display: block;
        text-align: center;
        width: 40%;
        font-size: 0.75rem;
    }

    .add-image-btn {
        display: block;
        text-align: center;
        width: 40%;
        font-size: 0.75rem;
    }
}
