/* App.css */

/* General reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* App Bar/Header Styling */
.app-bar {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  height: auto;
}


/* Button for creating a new Linktree */
.btn-createLinktree {
  display: flex;
  background-color:white; 
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: auto;
  margin-right: 2%;
  position: absolute;
  right: 0;

}

.btn-createLinktree:hover {
  background-color:aliceblue;
}


/* Header Title Styling for MBD Linktree */
.title {
  display: flex;
  align-items: center;
}

.title .logo {
  font-size: 1.7rem;
  text-decoration: none;
  margin-left: 10px;
  color: #007bff;
  /* Same purple for the logo */
}

.logo{
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 10px);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .app-bar {
   display: flex;
   justify-content: flex-start;
  }

  .btn-createLinktree {
    margin-top: 10px;
    font-size: 14px;
    padding: 8px 16px;
  }

  .title .logo {
    font-size: 22px;
  }
}


 .app-container {
    margin-top: 1%;
    width: 100%;
    
  }



.btn-createLinktree {
  display: block;
}

@media screen and (max-width: 768px) {

  .btn-createLinktree {
    display: none!important; 
  }

  .btn-details{
    display: none!important;
  }

  .btn-logout{
    display: none!important;
  }


  .container {
    margin: 20px auto;
    width: 90%;
  }
  
  .title .logo {
    font-size: 1.5rem;
    color: #333;
    text-decoration: none;
  }
  
  .btn-logout {
    border: none;
    background-color: #333;
    color: white;
    padding: 8px 16px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  .btn-createLinktree {
    border: 1px solid #333;
    background-color: white;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      width: 100%;
      padding: 0 20px;
    }

    .title .logo{
      color: #007bff;
    }
    
  }
}

a{
text-decoration: none;
}

.btn-logout {
  position: fixed!important;
  bottom: 20px;
  right: 20px;
  color: #007bff!important;
  cursor: pointer;
}

