/* Modal Konumlandırma ve Tasarım */
.edit-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh; /* Ekranın tamamını kapsar */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5); /* Arka planı karart */
  overflow: hidden; /* Taşma durumunda dışarı kaymasın */
}

/* Modal içeriği */
.edit-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  max-height: 80vh; /* Modalın maksimum yüksekliğini ekranın %80'i ile sınırla */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow-y: auto; /* İçerik taşarsa dikey scroll ekle */
}

/* Başlık */
h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-weight: 500;
}

/* Form Stilleri */
.form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #333;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

/* Add Link Butonu */
.add-button {
  display: block;
  text-align: center;
  margin-right: auto;
  background-color: white; 
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 2%;
  width: 30%; /* Buton genişliğini arttırdık */
}

.add-button:hover {
  background-color: aliceblue;
}

/* Renk Seçici */
.color-picker {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

/* Form Aksiyonları */
.form-actions {
  display: flex;
  justify-content: space-between;
}

/* Genel Buton Stilleri */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.cancel {
  background-color: #ccc;
}

.cancel:hover {
  background-color: #999;
}

.submit {
  background-color: white; 
  color: #007bff;
  border: 1px solid #007bff;
}

.submit:hover {
  background-color: aliceblue;
}

/* Mobil uyumlu tasarım */
@media (max-width: 768px) {
  .edit-modal-content {
    width: 90%;
    max-width: 100%;
    max-height: 80vh; /* Modal yüksekliği mobilde de %80 sınırında olacak */
    overflow-y: auto; /* İçerik taşarsa scroll */
  }

  .add-button {
    width: 40%; /* Buton genişliğini mobilde tam yap */
    display: block;
    text-align: center;
    font-size: 0.9rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .form-actions .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

.head-edit {
  text-align: start;
  font-size: 1.2rem;
  font-weight: 500;
}

.con-btn {
  background-color: white; 
  color: #007bff;
  border: 1px solid #007bff;
}

.delete-btn-icon {
  display: flex;
  margin-right: auto;
  margin-bottom: 5%;
}

.image-group {
  display: flex;
  flex-direction: row;
}

.button-group{
  display: flex;
  flex-direction: row;
}

.icon-group{
  display: flex;
  flex-direction: row;
}

.input-container{
  display: flex;
  flex-direction: column;
  width: 95%;
}

.delete-btn-icon-container{
  display: flex;
  align-items: center;
}

.content {
  margin-top: 10%;
}

@media (max-width: 376px) {
  .add-button {
    width: 40%; /* Buton genişliğini mobilde tam yap */
    display: block;
    text-align: center;
    font-size: 0.75rem;
  }

}
